import React, { useEffect, useState, useRef } from "react";
import { getImageFile, setImageFile } from "../../Services/StorageService";
import { LOGIN_BACKGROUND_HARDCODED_FOR_THURAYA } from "../../Utils/Constants";
const HomeSlideRight = (props) => {
	let { image, imgKey } = props;

	const [localImgFile, setLocalImgFile] = useState("");
	const imgRef = useRef();
	imgRef.current = imgKey;

	useEffect(() => {
		getImageFileFunc();
	}, [imgKey, localImgFile]);

	const getImageFileFunc = async () => {
		const imgFile = await getImageFile(imgKey);
		setLocalImgFile(imgFile);
	};

	let newBG = LOGIN_BACKGROUND_HARDCODED_FOR_THURAYA;

	let src;
	// if (imgKey) {
	// 	if (localImgFile) {
	// 		src = "url('" + localImgFile + "')";
	// 	} else {
	// 		src = "url('" + image + "')";
	// 		fetch(image)
	// 			.then(function (response) {
	// 				return response.blob();
	// 			})
	// 			.then(function (myBlob) {
	// 				const reader = new FileReader();
	// 				reader.onload = (event) => {
	// 					setImageFile(imgKey, event.target.result);
	// 				};
	// 				reader.readAsDataURL(myBlob);
	// 			});
	// 	}
	// } else {
	// 	src = "url('" + image + "')";
	// }

	src = `url(${newBG})`;

	return (
		// <img
		// 	loading="lazy"
		// 	className="slider-bg"
		// 	style={{
		// 		flex: 3,
		// 		background: src,
		// 		backgroundSize: "cover",
		// 	}}
		// />
		<div
			style={{
				flex: 4,
				backgroundImage: `url(${newBG})`,
				backgroundColor: "#444444",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				boxShadow: "2px 2px 10px -4px rgba(74,74,74,1)",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
			}}
		></div>
	);
};

export default HomeSlideRight;