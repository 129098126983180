import { MessageTypeConstants } from "../../../../Services/Message";
import {
	RENDER_MESSAGE_TYPE_TABLE,
	RENDER_MESSAGE_TYPE_CONTAINER,
	CHANGE_CONTAINER_SELECTED_TAB,
	RENDER_MESSAGE_TYPE_FORM2,
	RENDER_MESSAGE_TYPE_MENU,
	RENDER_MESSAGE_TYPE_CALENDAR,
	RENDER_MESSAGE_TYPE_MAP,
	RENDER_MESSAGE_TYPE_HTML,
	CHANGE_CALENDAR,
	RESET_FORM2_FIELDS,
	RESET_CONTAINER_FIELDS,
	REMOVE_APP,
	BG_CALENDAR_TOOLBAR,
	SAVE_MAP_SETTINGS,
	RENDER_MESSAGE_TYPE_VIDEOCALL,
	RENDER_CHANGE_NONCONVERSATION_SELECTED_TAB,
	RESET_NONCONVENTIONAL,
} from "./types";
import store from "../../../../State/configureStore";
import _ from "lodash";

import {
	handleMessageTypeNotification,
	handleMessageTypeNonVideoNotification,
} from "../../../Store/Notification/NotificationAction";
import {
	handleMessageTypeForm2ChangeAction,
	handleMessageTypeForm2ResultAction,
	handleMessageTypeTableChangeAction,
	handleMessageTypeInlineFormTableResultAction,
	handleMessageTypeTableChangeColumnTemplate,
	handleForm2PromptOnCloseAction,
} from "../NonConversationalHelpers/NonConversationalActionHelper";
import { renderNonconversationalFilter } from "../../../Store/NonConversationalFilter/NonConversationalFilterAction";

export const v2HandleNonConversationalServerMessages =
	(conversationId, message) => (dispatch) => {
		switch (message.messageType) {
			case MessageTypeConstants.MESSAGE_TYPE_MENU:
				dispatch(handleMessageTypeMenu(conversationId, message));
				break;
			case MessageTypeConstants.MESSAGE_TYPE_TABLE:
				if (
					["changeFilter", "validationFilter", "resultsFilter"].includes(
						message.options.action
					)
				) {
					dispatch(renderNonconversationalFilter(message));
				} else {
					dispatch(handleMessageTypeTable(conversationId, message, false, false));
				}
				break;
			case MessageTypeConstants.MESSAGE_TYPE_MAP:
				if (
					["changeFilter", "validationFilter", "resultsFilter"].includes(
						message.options.action
					)
				) {
					dispatch(renderNonconversationalFilter(message));
				} else {
					dispatch(handleMessageTypeMap(conversationId, message));
				}
				break;
			case MessageTypeConstants.MESSAGE_TYPE_CONTAINER:
				dispatch(handleMessageTypeContainer(conversationId, message));
				break;
			case MessageTypeConstants.MESSAGE_TYPE_FORM2:
				dispatch(handleMessageTypeForm2(conversationId, message));
				break;
			case MessageTypeConstants.MESSAGE_TYPE_CALENDAR:
				if (
					["changeFilter", "validationFilter", "resultsFilter"].includes(
						message.options.action
					)
				) {
					dispatch(renderNonconversationalFilter(message));
				} else {
					dispatch(handleMessageTypeCalendar(conversationId, message));
				}
				break;
			case MessageTypeConstants.MESSAGE_TYPE_VIDEO_CALL:
				if (parseInt(message.contentType) === 600) {
					dispatch(handleMessageTypeNotification(conversationId, message));
				}
				break;
			case MessageTypeConstants.MESSAGE_TYPE_HTML:
				dispatch(handleMessageTypeHtml(conversationId, message));
				break;
			case MessageTypeConstants.MESSAGE_TYPE_STD_NOTIFICATION:
			case MessageTypeConstants.MESSAGE_TYPE_CRITICAL_NOTIFICATION:
			case MessageTypeConstants.MESSAGE_TYPE_AUTHORIZATION_REQUEST:
				dispatch(
					handleMessageTypeNonVideoNotification(conversationId, message)
				);
				break;
		}
	};

export const handleChangeNonConversationalMessagesTab =
	(conversationId, selectedTab) => (dispatch) => {
		const nonConversationalState = store.getState().v2.NonConversational;
		let updatedNonConversationalData;

		if (nonConversationalState && !_.isEmpty(nonConversationalState)) {
			let nonConversationalData = nonConversationalState[conversationId];

			if (
				nonConversationalData &&
				!_.isEmpty(nonConversationalData) &&
				!_.isEmpty(nonConversationalData.components)
			) {
				updatedNonConversationalData = {
					...nonConversationalData,
					selectedTab: selectedTab,
				};
			} else {
				updatedNonConversationalData = {
					...nonConversationalData,
					selectedTab: selectedTab,
					components: [],
				};
			}

			dispatch({
				type: RENDER_CHANGE_NONCONVERSATION_SELECTED_TAB,
				data: {
					[conversationId]: updatedNonConversationalData,
				},
			});
		}
	};

export const handleMessageTypeMenu =
	(conversationId, message) => (dispatch) => {
		if (
			store.getState().v2.NonConversational &&
			!_.isEmpty(store.getState().v2.NonConversational)
		) {
			let NonConversationalData = _.cloneDeep(
				store.getState().v2.NonConversational[conversationId]
			);

			if (
				NonConversationalData &&
				!_.isEmpty(NonConversationalData) &&
				!_.isEmpty(NonConversationalData.components)
			) {
				let componentsList = []; // Use to maintain the order of tabs in components
				NonConversationalData.components.forEach((item, i) => {
					componentsList.push(
						NonConversationalData.components[i].options.tabId
					);
					if (
						item?.options?.tabId &&
						message?.options?.tabId &&
						item?.options?.tabId === message?.options?.tabId
					) {
						if (message?.options?.pullDown) {
							NonConversationalData.pullDownMenuComponent = message;
						} else {
							NonConversationalData.components[i] = message;
							if (
								!message?.message?.options?.hasOwnProperty(
									"updateInBackground"
								) ||
								!message?.message?.options?.updateInBackground
							) {
								NonConversationalData.selectedTab =
									message?.message?.options?.tabId;
							}
						}
					} else if (message?.options?.pullDown) {
						NonConversationalData.pullDownMenuComponent = message;
					}
				});
				if (
					!componentsList.includes(message.options.tabId) &&
					!message?.options?.pullDown
				) {
					NonConversationalData.components.push(message);
				}
			} else {
				if (message?.options?.pullDown) {
					NonConversationalData = {
						...NonConversationalData,
						components: [],
						pullDownMenuComponent: message,
					};
				} else {
					if (
						!message?.message?.options.hasOwnProperty("updateInBackground") ||
						!message?.message?.options?.updateInBackground
					) {
						NonConversationalData = {
							...NonConversationalData,
							selectedTab: message?.message?.options?.tabId,
							components: [message],
						};
					} else {
						NonConversationalData = {
							...NonConversationalData,
							components: [message],
						};
					}
				}
			}

			dispatch({
				type: RENDER_MESSAGE_TYPE_MENU,
				data: {
					[conversationId]: NonConversationalData,
				},
			});
		} else {
			let NonConversationalData = {};
			if (message?.options?.pullDown) {
				NonConversationalData = {
					components: [],
					pullDownMenuComponent: message,
				};
			} else {
				if (
					!message?.message?.options?.hasOwnProperty("updateInBackground") ||
					!message?.message?.options?.updateInBackground
				) {
					NonConversationalData = {
						selectedTab: message?.message?.options?.tabId,
						components: [message],
					};
				} else {
					NonConversationalData = {
						components: [message],
					};
				}
			}

			dispatch({
				type: RENDER_MESSAGE_TYPE_MENU,
				data: {
					[conversationId]: NonConversationalData,
				},
			});
		}
	};

export const handleMessageTypeVideoCall =
	(conversationId, message) => (dispatch) => {
		if (
			store.getState().v2.NonConversational &&
			!_.isEmpty(store.getState().v2.NonConversational)
		) {
			let NonConversationalData = _.cloneDeep(
				store.getState().v2.NonConversational[conversationId]
			);

			if (
				NonConversationalData &&
				!_.isEmpty(NonConversationalData) &&
				!_.isEmpty(NonConversationalData.components)
			) {
				let componentsList = []; // Use to maintain the order of tabs in components
				NonConversationalData.components.forEach((item, i) => {
					componentsList.push(
						NonConversationalData.components[i].options.tabId
					);
					if (
						item?.options?.tabId &&
						message?.options?.tabId &&
						item?.options?.tabId === message?.options?.tabId
					) {
						NonConversationalData.components[i] = message;
					}
				});
				if (!componentsList.includes(message.options.tabId)) {
					NonConversationalData.components.push(message);
				}
			} else {
				NonConversationalData = {
					...NonConversationalData,
					components: [message],
				};
			}
			if (
				!message?.options?.hasOwnProperty("updateInBackground") ||
				!message?.options?.updateInBackground
			) {
				NonConversationalData.selectedTab = message?.options?.tabId;
			}
			dispatch({
				type: RENDER_MESSAGE_TYPE_VIDEOCALL,
				data: {
					[conversationId]: NonConversationalData,
				},
			});
		} else {
			if (
				!message?.options?.hasOwnProperty("updateInBackground") ||
				!message?.options?.updateInBackground
			) {
				dispatch({
					type: RENDER_MESSAGE_TYPE_VIDEOCALL,
					data: {
						[conversationId]: {
							selectedTab: message?.options?.tabId,
							components: [message],
						},
					},
				});
			} else {
				dispatch({
					type: RENDER_MESSAGE_TYPE_VIDEOCALL,
					data: {
						[conversationId]: {
							components: [message],
						},
					},
				});
			}
		}
	};
const updateNonConversationalDataWithAction = (
	nonConversationalData,
	message,
	isNewRow,
	propmtOnClose
) => {
	const action = message?.options?.action;
	let updatedNonConversationalData;
	switch (action) {
		case "change": {
			updatedNonConversationalData = handleMessageTypeTableChangeAction(
				nonConversationalData,
				message,
				propmtOnClose,
				isNewRow
			);

			return updatedNonConversationalData;
		}
		case "validation":
		case "results": {
			updatedNonConversationalData =
				handleMessageTypeInlineFormTableResultAction(
					nonConversationalData,
					message
				);
			return updatedNonConversationalData;
		}
		case "changeColumnTemplate": {
			updatedNonConversationalData = handleMessageTypeTableChangeColumnTemplate(
				nonConversationalData,
				message
			);
			return updatedNonConversationalData;
		}
		default:
			return nonConversationalData;
	}
};

const updateNonConversationalDataWithoutAction = (
	nonConversationalData,
	message,
	dispatch
) => {
	const nonConversationalDataExists =
		nonConversationalData && !_.isEmpty(nonConversationalData);
	const nonConversationalComponentDataExists =
		nonConversationalDataExists && !_.isEmpty(nonConversationalData.components);
	let updatedComponents = [];
	if (nonConversationalComponentDataExists) {
		const openDrawer = message.options.hasOwnProperty("openDrawer")
			? message.options.openDrawer
			: false;
		const showFilterSearchInput = message.options.hasOwnProperty(
			"showFilterSearchInput"
		)
			? message.options.showFilterSearchInput
			: false;
		const view = message.options.hasOwnProperty("view")
			? message.options.view
			: "month";
		const style = message.options.hasOwnProperty("style")
			? message.options.style
			: "calendar";
		const isNewFilter = message.options.hasOwnProperty("isNewFilter")
			? message.options.isNewFilter
			: false;

		const componentsList = nonConversationalData.components.map(
			(item) => item.options.tabId
		);

		updatedComponents = nonConversationalData.components.map((item, i) => {
			if (
				item?.options?.tabId &&
				message?.options?.tabId &&
				item?.options?.tabId === message?.options?.tabId
			) {
				let updatedItem = {
					...message,
					options: {
						...message.options,
						view: item.options.view,
						style: item.options.style,
						openDrawer: openDrawer,
						showFilterSearchInput: showFilterSearchInput,
						isNewFilter: isNewFilter,
					},
				};
				if (updatedItem.options.openDrawer) {
					dispatch(renderNonconversationalFilter(updatedItem));
				}
				return updatedItem;
			}
			return item;
		});

		if (!componentsList.includes(message.options.tabId)) {
			updatedComponents.push({
				...message,
				options: {
					...message.options,
					view,
					style,
					openDrawer,
					showFilterSearchInput,
					isNewFilter,
				},
			});
		}
	} else {
		updatedComponents = [message];
	}
	return { ...nonConversationalData, components: updatedComponents };
};

export const handleMessageTypeTable =
	(conversationId, message, isNewRow = false, propmtOnClose = true) => (dispatch) => {
		const nonConversationalState = store.getState().v2.NonConversational;
		const nonConversationalStateExists =
			nonConversationalState && !_.isEmpty(nonConversationalState);

		if (nonConversationalStateExists) {
			const nonConversationalData = nonConversationalState?.[conversationId];
			let updatedNonConversationalData;

			if (message?.options?.action) {
				updatedNonConversationalData = updateNonConversationalDataWithAction(
					nonConversationalData,
					message,
					isNewRow,
					propmtOnClose
				);
			} else {
				updatedNonConversationalData = updateNonConversationalDataWithoutAction(
					nonConversationalData,
					message,
					dispatch
				);
			}

			if (!message.options.updateInBackground) {
				updatedNonConversationalData = {
					...updatedNonConversationalData,
					selectedTab: message?.options?.tabId,
				};
			}

			dispatch({
				type: RENDER_MESSAGE_TYPE_TABLE,
				data: { [conversationId]: updatedNonConversationalData },
			});
		} else {
			const data = {
				[conversationId]: {
					components: [message],
				},
			};

			if (!message.options.updateInBackground) {
				data[conversationId].selectedTab = message.options.tabId;
			}

			dispatch({
				type: RENDER_MESSAGE_TYPE_TABLE,
				data: data,
			});
		}
	};

export const handleMessageTypeCalendar =
	(conversationId, message) => (dispatch) => {
		if (
			store.getState().v2.NonConversational &&
			!_.isEmpty(store.getState().v2.NonConversational)
		) {
			let NonConversationalData = [];
			NonConversationalData = _.cloneDeep(
				store.getState().v2.NonConversational[conversationId]
			);

			if (message?.options?.action) {
				switch (message?.options?.action) {
					case "change":
						NonConversationalData = handleMessageTypeTableChangeAction(
							NonConversationalData,
							message,
							true
						);
						break;
					case "changeColumnTemplate":
						NonConversationalData = handleMessageTypeTableChangeColumnTemplate(
							NonConversationalData,
							message
						);
						break;
				}
			} else {
				if (
					NonConversationalData &&
					!_.isEmpty(NonConversationalData) &&
					!_.isEmpty(NonConversationalData.components)
				) {
					let openDrawer, showFilterSearchInput, view, style, isNewFilter;
					if (
						message &&
						message.options &&
						message.options.hasOwnProperty("openDrawer")
					) {
						openDrawer = message.options.openDrawer;
					} else {
						openDrawer = false;
					}
					if (
						message &&
						message.options &&
						message.options.hasOwnProperty("showFilterSearchInput")
					) {
						showFilterSearchInput = message.options.showFilterSearchInput;
					} else {
						showFilterSearchInput = false;
					}
					if (
						message &&
						message.options &&
						message.options.hasOwnProperty("view")
					) {
						view = message.options.view;
					} else {
						view = "month";
					}
					if (
						message &&
						message.options &&
						message.options.hasOwnProperty("style")
					) {
						style = message.options.style;
					} else {
						style = "calendar";
					}
					if (
						message &&
						message.options &&
						message.options.hasOwnProperty("isNewFilter")
					) {
						isNewFilter = message.options.isNewFilter;
					} else {
						isNewFilter = false;
					}

					let componentsList = []; // Use to maintain the order of tabs in components
					NonConversationalData.components.forEach((item, i) => {
						componentsList.push(
							NonConversationalData.components[i].options.tabId
						);
						if (
							(item?.options?.tabId && message?.options?.tabId,
								item?.options?.tabId === message?.options?.tabId)
						) {
							view = NonConversationalData.components[i].options?.view;
							style = NonConversationalData.components[i].options?.style;
							NonConversationalData.components[i] = message;
							NonConversationalData.components[i].options.view = view;
							NonConversationalData.components[i].options.style = style;
							NonConversationalData.components[i].options.openDrawer =
								NonConversationalData.components[i].options.openDrawer ||
								openDrawer;
							NonConversationalData.components[
								i
							].options.showFilterSearchInput =
								NonConversationalData.components[i].options
									.showFilterSearchInput || showFilterSearchInput;
							NonConversationalData.components[i].options.isNewFilter =
								NonConversationalData.components[i].options.isNewFilter ||
								isNewFilter;
							if (NonConversationalData.components[i].options.openDrawer) {
								dispatch(
									renderNonconversationalFilter(
										NonConversationalData.components[i]
									)
								);
							}
						}
					});
					if (!componentsList.includes(message.options.tabId)) {
						message.options.view = view;
						message.options.style = style;
						message.options.openDrawer = openDrawer;
						message.options.showFilterSearchInput = showFilterSearchInput;
						message.options.isNewFilter = isNewFilter;
						NonConversationalData.components.push(message);
					}
				} else {
					NonConversationalData = {
						...NonConversationalData,
						components: [message],
					};
				}
			}
			if (
				!message?.options?.hasOwnProperty("updateInBackground") ||
				!message?.options?.updateInBackground
			) {
				NonConversationalData.selectedTab = message.options.tabId;
			}
			dispatch({
				type: RENDER_MESSAGE_TYPE_CALENDAR,
				data: {
					[conversationId]: NonConversationalData,
				},
			});
		} else {
			if (
				!message?.options?.hasOwnProperty("updateInBackground") ||
				!message?.options?.updateInBackground
			) {
				dispatch({
					type: RENDER_MESSAGE_TYPE_CALENDAR,
					data: {
						[conversationId]: {
							selectedTab: message.options.tabId,
							components: [message],
						},
					},
				});
			} else {
				dispatch({
					type: RENDER_MESSAGE_TYPE_CALENDAR,
					data: {
						[conversationId]: {
							components: [message],
						},
					},
				});
			}
		}
	};

export const handleCalendarChange =
	(conversationId, options, field, value, parentTabId) => (dispatch) => {
		if (
			store.getState().v2.NonConversational &&
			!_.isEmpty(store.getState().v2.NonConversational)
		) {
			let NonConversationalData = _.cloneDeep(
				store.getState().v2.NonConversational[conversationId]
			);

			if (
				NonConversationalData &&
				!_.isEmpty(NonConversationalData) &&
				!_.isEmpty(NonConversationalData.components)
			) {
				NonConversationalData.components.forEach((item, i) => {
					if (
						item?.options?.tabId &&
						options?.tabId &&
						item?.options?.tabId === options?.tabId
					) {
						NonConversationalData.components[i].options[field] = value;
					}
					if (parentTabId === item?.options?.tabId) {
						item.message.forEach((itm) => {
							if (itm?.message?.options?.tabId === options?.tabId) {
								itm.message.options.style = value;
							}
						});
					}
				});
			}

			dispatch({
				type: CHANGE_CALENDAR,
				data: {
					[conversationId]: NonConversationalData,
				},
			});
		}
	};

export const handleCalendarToolBarChange = (payload) => (dispatch) => {
	dispatch({
		type: BG_CALENDAR_TOOLBAR,
		data: {
			bgClndrToolBarState: payload,
		},
	});
};

export const handleMapSettingsChange = (payload) => (dispatch) => {
	dispatch({
		type: SAVE_MAP_SETTINGS,
		data: {
			mapSettingsState: payload,
		},
	});
};

export const handleMessageTypeMap = (conversationId, message) => (dispatch) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = [];
		NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		if (message?.options?.action) {
			switch (message?.options?.action) {
				case "change":
					NonConversationalData = handleMessageTypeTableChangeAction(
						NonConversationalData,
						message,
						true
					);
					break;
				case "changeColumnTemplate":
					NonConversationalData = handleMessageTypeTableChangeColumnTemplate(
						NonConversationalData,
						message
					);
					break;
			}
		} else {
			if (
				NonConversationalData &&
				!_.isEmpty(NonConversationalData) &&
				!_.isEmpty(NonConversationalData.components)
			) {
				let openDrawer, showFilterSearchInput, view, style, isNewFilter;
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("openDrawer")
				) {
					openDrawer = message.options.openDrawer;
				} else {
					openDrawer = false;
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("showFilterSearchInput")
				) {
					showFilterSearchInput = message.options.showFilterSearchInput;
				} else {
					showFilterSearchInput = false;
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("view")
				) {
					view = message.options.view;
				} else {
					view = "month";
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("style")
				) {
					style = message.options.style;
				} else {
					style = "map";
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("isNewFilter")
				) {
					isNewFilter = message.options.isNewFilter;
				} else {
					isNewFilter = false;
				}

				let componentsList = []; // Use to maintain the order of tabs in components
				NonConversationalData.components.forEach((item, i) => {
					componentsList.push(
						NonConversationalData.components[i].options.tabId
					);
					if (
						(item?.options?.tabId && message?.options?.tabId,
							item?.options?.tabId === message?.options?.tabId)
					) {
						view = NonConversationalData.components[i].options?.view;
						style = NonConversationalData.components[i].options?.style;
						NonConversationalData.components[i] = message;
						NonConversationalData.components[i].options.view = view;
						NonConversationalData.components[i].options.style = style;
						NonConversationalData.components[i].options.openDrawer =
							NonConversationalData.components[i].options.openDrawer ||
							openDrawer;
						NonConversationalData.components[i].options.showFilterSearchInput =
							NonConversationalData.components[i].options
								.showFilterSearchInput || showFilterSearchInput;
						NonConversationalData.components[i].options.isNewFilter =
							NonConversationalData.components[i].options.isNewFilter ||
							isNewFilter;
						if (NonConversationalData.components[i].options.openDrawer) {
							dispatch(
								renderNonconversationalFilter(
									NonConversationalData.components[i]
								)
							);
						}
					}
				});
				if (!componentsList.includes(message.options.tabId)) {
					message.options.view = view;
					message.options.style = style;
					message.options.openDrawer = openDrawer;
					message.options.showFilterSearchInput = showFilterSearchInput;
					message.options.isNewFilter = isNewFilter;
					NonConversationalData.components.push(message);
				}
			} else {
				NonConversationalData = {
					...NonConversationalData,
					components: [message],
				};
			}
		}
		if (
			!message?.options?.hasOwnProperty("updateInBackground") ||
			!message?.options?.updateInBackground
		) {
			NonConversationalData.selectedTab = message.options.tabId;
		}
		dispatch({
			type: RENDER_MESSAGE_TYPE_MAP,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		if (
			!message?.options?.hasOwnProperty("updateInBackground") ||
			!message?.options?.updateInBackground
		) {
			dispatch({
				type: RENDER_MESSAGE_TYPE_MAP,
				data: {
					[conversationId]: {
						selectedTab: message.options.tabId,
						components: [message],
					},
				},
			});
		} else {
			dispatch({
				type: RENDER_MESSAGE_TYPE_MAP,
				data: {
					[conversationId]: {
						components: [message],
					},
				},
			});
		}
	}
};

const updateMessageStyle = (message, item) => {
	return message.map((msg, index) => {
		const newMessage = { ...msg };
		newMessage.message.options.style =
			item.message[index].message.options.style;
		return newMessage;
	});
};

const createUpdatedComponents = (nonConversationalData, message) => {
	let componentsList = [];
	let found = false;

	const updatedComponents = nonConversationalData.components.map((item) => {
		componentsList.push(item.options.tabId);
		const itemTabId = item.options && item.options.tabId;
		const messageTabId = message.options && message.options.tabId;

		if (itemTabId && messageTabId && itemTabId === messageTabId) {
			found = true;
			const updatedMessages = updateMessageStyle(message.message, item);
			const updatedMessage = {
				...message,
				message: updatedMessages,
				containerSelectedTabIndex: item.containerSelectedTabIndex,
			};
			return updatedMessage;
		}
		return item;
	});

	if (!found) {
		updatedComponents.push({
			...message,
			containerSelectedTabIndex:
				nonConversationalData.containerSelectedTabIndex || 0,
		});
	}

	return updatedComponents;
};

const dispatchMessageTypeContainer = (dispatch, conversationId, data) => {
	dispatch({
		type: RENDER_MESSAGE_TYPE_CONTAINER,
		data: { [conversationId]: data },
	});
};

const handleNewConversation = (dispatch, conversationId, message) => {
	const updateInBackground =
		message.options && message.options.updateInBackground;
	const data = { components: [message] };

	if (!updateInBackground) {
		data.selectedTab = message.options.tabId;
	}

	data.containerSelectedTabIndex = 0;

	dispatchMessageTypeContainer(dispatch, conversationId, data);
};

const handleExistingConversation = (
	dispatch,
	conversationId,
	nonConversationalData,
	message
) => {
	const updatedComponents = createUpdatedComponents(
		nonConversationalData,
		message
	);

	const updatedNonConversationalData = {
		...nonConversationalData,
		components: updatedComponents,
	};

	const updateInBackground =
		message.options && message.options.updateInBackground;
	if (!updateInBackground) {
		updatedNonConversationalData.selectedTab = message.options.tabId;
	}

	dispatchMessageTypeContainer(
		dispatch,
		conversationId,
		updatedNonConversationalData
	);
};

export const handleMessageTypeContainer =
	(conversationId, message) => (dispatch) => {
		const nonConversationalState = store.getState().v2.NonConversational;

		if (nonConversationalState && !_.isEmpty(nonConversationalState)) {
			const nonConversationalData = nonConversationalState[conversationId];

			if (
				nonConversationalData &&
				!_.isEmpty(nonConversationalData) &&
				!_.isEmpty(nonConversationalData.components)
			) {
				handleExistingConversation(
					dispatch,
					conversationId,
					nonConversationalData,
					message
				);
			} else {
				handleNewConversation(dispatch, conversationId, message);
			}
		} else {
			handleNewConversation(dispatch, conversationId, message);
		}
	};

export const handleContainerTabChange =
	(conversationId, message, containerSelectedTabIndex) => (dispatch) => {
		const nonConversationalState = store.getState().v2.NonConversational;

		if (!_.isEmpty(nonConversationalState)) {
			const nonConversationalData = nonConversationalState[conversationId];

			if (
				!_.isEmpty(nonConversationalData) &&
				!_.isEmpty(nonConversationalData.components)
			) {
				const updatedComponents = nonConversationalData.components.map(
					(item) => {
						const itemTabId = item.options?.tabId;
						const messageTabId = message.options?.tabId;

						if (itemTabId && messageTabId && itemTabId === messageTabId) {
							return {
								...item,
								containerSelectedTabIndex: containerSelectedTabIndex,
							};
						}
						return item;
					}
				);

				const updatedNonConversationalData = {
					...nonConversationalData,
					components: updatedComponents,
				};

				dispatch({
					type: CHANGE_CONTAINER_SELECTED_TAB,
					data: {
						[conversationId]: updatedNonConversationalData,
					},
				});
			}
		}
	};

export const resetContainerFields = (conversationId, message) => (dispatch) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		// search for the message
		for (let i = 0; i < NonConversationalData.components.length; i++) {
			if (
				NonConversationalData.components[i] &&
				NonConversationalData.components[i].options &&
				message?.options &&
				NonConversationalData.components[i].options.tabId ===
				message.options.tabId
			) {
				for (
					let j = 0;
					j < NonConversationalData.components[i].message.length;
					j++
				) {
					// check if it is form type
					if (
						NonConversationalData.components[i].message[j].type ===
						MessageTypeConstants.MESSAGE_TYPE_FORM2
					) {
						// reset for fields in forms
						for (
							let k = 0;
							k <
							NonConversationalData.components[i].message[j].message.fields
								.length;
							k++
						) {
							if (
								!NonConversationalData.components[i].message[j].message.fields[
									k
								].readOnly
							) {
								NonConversationalData.components[i].message[j].message.fields[
									k
								].value = "";
								if (
									NonConversationalData.components[i].message[j].message.fields[
										k
									].results &&
									!_.isEmpty(
										NonConversationalData.components[i].message[j].message
											.fields[k].results
									)
								) {
									NonConversationalData.components[i].message[j].message.fields[
										k
									].results = [];
								}
							}
						}
					}
				}
			}
		}

		dispatch({
			type: RESET_CONTAINER_FIELDS,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	}
};

export const handleMessageTypeForm2 =
	(conversationId, message) => (dispatch) => {
		if (
			store.getState().v2.NonConversational &&
			!_.isEmpty(store.getState().v2.NonConversational)
		) {
			let NonConversationalData = _.cloneDeep(
				store.getState().v2.NonConversational[conversationId]
			);
			// Handle if any change in form
			if (message?.options?.action) {
				switch (message?.options?.action) {
					case "change":
						NonConversationalData = handleMessageTypeForm2ChangeAction(
							NonConversationalData,
							message,
							true
						);
						break;
					case "validation":
					case "results":
						NonConversationalData = handleMessageTypeForm2ResultAction(
							NonConversationalData,
							message
						);
						break;
				}
			} else {
				if (
					NonConversationalData &&
					!_.isEmpty(NonConversationalData) &&
					!_.isEmpty(NonConversationalData.components)
				) {
					let componentsList = []; // Use to maintain the order of tabs in components
					NonConversationalData.components.forEach((item, i) => {
						componentsList.push(
							NonConversationalData.components[i].options.tabId
						);
						if (
							item?.options?.tabId &&
							message?.options?.tabId &&
							item?.options?.tabId === message?.options?.tabId
						) {
							NonConversationalData.components[i] = message;
						}
					});
					if (!componentsList.includes(message.options.tabId)) {
						NonConversationalData.components.push(message);
					}
				} else {
					NonConversationalData = {
						...NonConversationalData,
						components: [message],
					};
				}
				if (
					!message?.options?.hasOwnProperty("updateInBackground") ||
					!message?.options?.updateInBackground
				) {
					NonConversationalData.selectedTab = message?.options?.tabId;
				}
			}
			dispatch({
				type: RENDER_MESSAGE_TYPE_FORM2,
				data: {
					[conversationId]: NonConversationalData,
				},
			});
		} else {
			if (
				!message?.options?.hasOwnProperty("updateInBackground") ||
				!message?.options?.updateInBackground
			) {
				dispatch({
					type: RENDER_MESSAGE_TYPE_FORM2,
					data: {
						[conversationId]: {
							selectedTab: message?.options?.tabId,
							components: [message],
						},
					},
				});
			} else {
				dispatch({
					type: RENDER_MESSAGE_TYPE_FORM2,
					data: {
						[conversationId]: {
							components: [message],
						},
					},
				});
			}
		}
	};

export const handlePromptOnClose = (conversationId, promptOnClose = false) => (dispatch) => {
	const nonConversationalDataFromState = store.getState().v2.NonConversational[conversationId];
	let nonConversationalData = handleForm2PromptOnCloseAction(nonConversationalDataFromState, promptOnClose);

	dispatch({
		type: RENDER_MESSAGE_TYPE_FORM2,
		data: {
			[conversationId]: nonConversationalData,
		},
	});

}

export const resetForm2Fields = (conversationId, message) => (dispatch) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		// search for the message
		for (let i = 0; i < NonConversationalData.components.length; i++) {
			if (
				NonConversationalData.components[i] &&
				NonConversationalData.components[i].options &&
				message?.options &&
				NonConversationalData.components[i].options.tabId ===
				message.options.tabId
			) {
				// search for the non readonly field and reset the value
				for (
					let j = 0;
					j < NonConversationalData.components[i].message.length;
					j++
				) {
					if (!NonConversationalData.components[i].message[j].readOnly) {
						NonConversationalData.components[i].message[j].value = "";
						if (
							NonConversationalData.components[i].message[j].results &&
							!_.isEmpty(NonConversationalData.components[i].message[j].results)
						) {
							NonConversationalData.components[i].message[j].results = [];
						}
					}
				}
			}
		}

		dispatch({
			type: RESET_FORM2_FIELDS,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	}
};

export const handleMessageTypeHtml =
	(conversationId, message) => (dispatch) => {
		if (
			store.getState().v2.NonConversational &&
			!_.isEmpty(store.getState().v2.NonConversational)
		) {
			let NonConversationalData = _.cloneDeep(
				store.getState().v2.NonConversational[conversationId]
			);
			if (!message.options.external) {
				if (
					NonConversationalData &&
					!_.isEmpty(NonConversationalData) &&
					!_.isEmpty(NonConversationalData.components)
				) {
					let componentsList = []; // Use to maintain the order of tabs in components
					NonConversationalData.components.forEach((item, i) => {
						componentsList.push(
							NonConversationalData.components[i].options.tabId
						);
						if (
							item?.options?.tabId &&
							message?.options?.tabId &&
							item?.options?.tabId === message?.options?.tabId
						) {
							NonConversationalData.components[i] = message;
						}
					});
					if (!componentsList.includes(message.options.tabId)) {
						NonConversationalData.components.push(message);
					}
				} else {
					NonConversationalData = {
						...NonConversationalData,
						components: [message],
					};
				}
				if (
					!message?.options?.hasOwnProperty("updateInBackground") ||
					!message?.options?.updateInBackground
				) {
					NonConversationalData.selectedTab = message?.options?.tabId;
				}
			} else {
				window.open(message.options.url, "_blank");
			}

			dispatch({
				type: RENDER_MESSAGE_TYPE_HTML,
				data: {
					[conversationId]: NonConversationalData,
				},
			});
		} else {
			if (
				!message?.options?.hasOwnProperty("updateInBackground") ||
				!message?.options?.updateInBackground
			) {
				dispatch({
					type: RENDER_MESSAGE_TYPE_HTML,
					data: {
						[conversationId]: {
							selectedTab: message.options.tabId,
							components: [message],
						},
					},
				});
			} else {
				dispatch({
					type: RENDER_MESSAGE_TYPE_HTML,
					data: {
						[conversationId]: {
							components: [message],
						},
					},
				});
			}
		}
	};

// Remove the component associated with the deleted tab
const removeDeletedComponent = (components, deletedTabId) => {
	return components.filter((item) => item?.options?.tabId !== deletedTabId);
};

const getNewSelectedTab = (components, currentSelectedTab, deletedTabId) => {
	if (deletedTabId !== currentSelectedTab) {
		// Case 1: An unselected tab is deleted, keep the current selected tab
		return currentSelectedTab;
	}

	const deletedTabIndex = components.findIndex(
		(item) => item?.options?.tabId === deletedTabId
	);

	// Case 2: Current selected tab is deleted and there is a previous tab
	if (deletedTabIndex > 0) {
		const previousTab = components[deletedTabIndex - 1];
		if (previousTab?.options?.tabId) {
			return previousTab.options.tabId;
		}
	}

	// Case 3: Current selected tab is deleted and there is no previous tab but there is a next tab
	if (deletedTabIndex < components.length) {
		const nextTab = components[deletedTabIndex + 1];
		if (nextTab?.options?.tabId) {
			return nextTab.options.tabId;
		}
	}

	// Case 4: Current selected tab is deleted and there is neither a previous nor a next tab
	return "";
};

// Create a new non-conversational data object
const createUpdatedNonConversationalData = (
	nonConversationalData,
	updatedComponents,
	newSelectedTab
) => {
	return {
		...nonConversationalData,
		components: updatedComponents,
		selectedTab: newSelectedTab,
	};
};

// The main function
export const removeNonConversationalMessages =
	(conversationId, message) => (dispatch) => {
		const nonConversationalData =
			store.getState().v2.NonConversational[conversationId];
		const deletedTabId = message?.options?.tabId;

		if (
			!_.isEmpty(nonConversationalData) &&
			!_.isEmpty(nonConversationalData.components) &&
			deletedTabId
		) {
			const updatedComponents = removeDeletedComponent(
				nonConversationalData.components,
				deletedTabId
			);
			const newSelectedTab = getNewSelectedTab(
				nonConversationalData.components,
				nonConversationalData.selectedTab,
				deletedTabId
			);
			const updatedNonConversationalData = createUpdatedNonConversationalData(
				nonConversationalData,
				updatedComponents,
				newSelectedTab
			);

			dispatch({
				type: REMOVE_APP,
				data: {
					[conversationId]: updatedNonConversationalData,
				},
			});
		}
	};

export const resetNonConversational = () => (dispatch) => {
	dispatch({
		type: RESET_NONCONVENTIONAL,
		data: {},
	});
};

export const updateFilterFlags = (conversationId, message) => (dispatch) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		if (
			NonConversationalData &&
			!_.isEmpty(NonConversationalData) &&
			!_.isEmpty(NonConversationalData.components)
		) {
			NonConversationalData.components.forEach((item) => {
				if (
					item?.options?.tabId &&
					message?.options?.tabId &&
					item?.options?.tabId === message?.options?.tabId
				) {
					let newMessage = _.cloneDeep(item);
					newMessage.options = message.options;
					switch (item.messageType) {
						case MessageTypeConstants.MESSAGE_TYPE_CALENDAR:
							dispatch(handleMessageTypeCalendar(conversationId, newMessage));
							break;
						case MessageTypeConstants.MESSAGE_TYPE_MAP:
							dispatch(handleMessageTypeTable(conversationId, newMessage));
							break;
						case MessageTypeConstants.MESSAGE_TYPE_TABLE:
							dispatch(handleMessageTypeTable(conversationId, newMessage));
							break;
					}
				}
				if (message?.options?.parentTabId === item?.options?.tabId) {
					item.message.forEach((itm) => {
						if (itm?.message?.options?.tabId === message?.options?.tabId) {
							itm.message.options.view = message.options.view;
							itm.message.options.style = message.options.style;
							itm.message.options.openDrawer = message.options.openDrawer;
							itm.message.options.showFilterSearchInput =
								message.options.showFilterSearchInput;
							itm.message.options.isNewFilter = message.options.isNewFilter;
							itm.message.options.parentTabId = item?.options?.tabId;
							if (itm.message.options.openDrawer) {
								dispatch(renderNonconversationalFilter(itm.message));
							}
						}
					});
					let newMessageContainer = _.cloneDeep(item);
					dispatch(
						handleMessageTypeContainer(conversationId, newMessageContainer)
					);
				}
			});
		}
	}
};
