export const ADMINISTRATOR = "ADMIN";
export const DEVELOPER = "DEVELOPER";
export const TESTER = "QA";
export const ENDUSER = "ENDUSER";
export const FRONTM_DOMAIN = "frontmai";
export const IM_BOT = "im-bot";
export const FRONTM_BOT_ID = "onboarding-bot";
export const BANNER_TYPE_INFO = "infoMsg";
export const BANNER_TYPE_ERROR = "errorMsg";
export const BANNER_TYPE_SUCCESS = "successMsg";
export const COLOR_TYPE_INFO = "#19b373";
export const COLOR_TYPE_ERROR = "#fbbaba";
export const COLOR_TYPE_MIX = "#FEEFB3";
export const COLOR_TYPE_OFFLINE = "#FEEFB3";
export const FRONTM_WEB_BOT = {
	botId: FRONTM_BOT_ID,
	botName: "FrontM Assistant",
	// logoUrl: "AuthenticationLogo.png",
	description: "Onboarding bot for FrontM",
	logoUrl: "AuthenticationLogo.png",
	userDomain: "frontmai",
};
export const VOYAGER_VOICE_AUTH_CODE = "gnsTestBots";
export const HELPER_MESSAGE =
	"Please contact FrontM support. You have been logged in to default FrontM domain";

export const ACTIVATE_ENTERPRISE_BOTS_ERROR_CODES = [
	"Invalid code",
	"Code has been already used by the user",
	"Code could not be applied. The code has expired or the maximum limit has been reached",
];
export const ONBOARDINGBOT = "onboarding-bot";

/* ====== Sign-up pages ========= */
export const VOYAGER_LANDING = "/voyagervoice";
export const VIKAND_LANDING = "/vikand";
export const VIKAND_DIRECT_LANDING = "/vikanddirect";
export const INTELL4DESK_LANDING = "/intelli4desks";
export const THURAYA_LANDING = "/thuraya";
export const BEACONASSIST_LANDING = "/beaconassist";
export const AAGEHEMPEL_LANDING = "/aagehempel";
export const ONSHIP_LANDING = "/onship";
export const THURAYA_T2M_LANDING = "/sattrackland";
export const AAGEHEMPEL_SIGNUP_LANDING = "/aagehempel/signup";
export const ONSHIP_SIGNUP_LANDING = "/onship/signup";
export const THURAYA_T2M_SIGNUP_LANDING = "/sattrackland/signup";
export const STATION_SATCOM_LANDING = "/stationsatcom";
export const SIGMA_LANDING = "/sigma";
export const ONECARE_LANDING = "/onecare";
export const FRONTM_LANDING = "/signup";
export const FRONTM_LOGIN = "/login";
export const FRONTM_TITLE = "FrontM Platform";
export const AAGEHEMPEL_TITLE = "SEAMPLIFY";
export const ONSHIP_TITLE = "onship";
export const THURAYA_T2M_TITLE = "sattrackland";
export const THURAYA_TITLE = "Thuraya";
export const VIKAND_TITLE = "Vikand";
export const BEACON_ASSIST_TITLE = "Beacon Assist";
export const ONECARE_TITLE = "OneCare Solutions";
export const STATION_SATCOM_TITLE = "Station Satcom";
export const SIGMA_TITLE = "Sigma";

export const LANDING_PATH_LIST = [
	VOYAGER_LANDING,
	VIKAND_LANDING,
	VIKAND_DIRECT_LANDING,
	INTELL4DESK_LANDING,
	THURAYA_LANDING,
	THURAYA_T2M_LANDING,
	BEACONASSIST_LANDING,
	AAGEHEMPEL_LANDING,
	AAGEHEMPEL_SIGNUP_LANDING,
	STATION_SATCOM_LANDING,
	SIGMA_LANDING,
	ONECARE_LANDING,
	FRONTM_LANDING,
	FRONTM_LOGIN,
];
/* ======= Sign-in Header Text ==== */

export const VOYAGER_LOGIN_MAIN_HEADER_TEXT = "Welcome!";
export const THURAYA_LOGIN_MAIN_HEADER_TEXT = "Welcome!";
export const INTELL4DESK_LOGIN_MAIN_HEADER_TEXT = "Welcome!";
export const VIKAND_LOGIN_MAIN_HEADER_TEXT = "";
export const VOYAGER_LOGIN_SUB_HEADER_TEXT = "Log in to Voyager Voice";
export const VIKAND_SIGNUP_SUB_HEADER_TEXT = "Create New Account";
export const INTELL4DESK_SIGNUP_SUB_HEADER_TEXT = "Create New Account";
export const VIKAND_LOGIN_SUB_HEADER_TEXT = "Login to VIKAND Covid-19 Helpline";
export const VIKAND_DIRECT_LOGIN_SUB_HEADER_TEXT = "Login to VIKAND";
export const INTELL4DESK_LOGIN_SUB_HEADER_TEXT = "Login to Intelli4desks";
export const THURAYA_LOGIN_SUB_HEADER_TEXT = "Log in to Thuraya SatTrack";
export const STATION_SATCOM_LOGIN_MAIN_HEADER_TEXT = "Welcome!";
export const SIGMA_LOGIN_MAIN_HEADER_TEXT = "Experience Seamless Connectivity";
export const STATION_SATCOM_LOGIN_SUB_HEADER_TEXT = "Log in to Station Satcom";
export const STATION_VIKAND_LOGIN_SUB_HEADER_TEXT = "Log in to Vikand";
export const AAGEHEMPEL_LOGIN_MAIN_HEADER_TEXT = "Welcome!";
export const AAGEHEMPEL_LOGIN_SUB_HEADER_TEXT = "Log in to Seamplify";
export const ONSHIP_LOGIN_MAIN_HEADER_TEXT = "Welcome!";
export const ONSHIP_LOGIN_SUB_HEADER_TEXT = "";
export const SIGMA_LOGIN_SUB_HEADER_TEXT = "";
export const ONECARE_LOGIN_MAIN_HEADER_TEXT = "Welcome!";
export const ONECARE_LOGIN_SUB_HEADER_TEXT = "Log in to OneCare Solutions";
export const THURAYA_T2M_LOGIN_MAIN_HEADER_TEXT = "";
export const THURAYA_T2M_LOGIN_SUB_HEADER_TEXT = "";
export const BEACONASSIST_LANDING_LOGIN_MAIN_HEADER_TEXT = "Welcome!";
export const BEACONASSIST_LANDING_LOGIN_SUB_HEADER_TEXT =
	"Log in to Beacon Assist";
/* ======= Sign-up form Text ===== */

export const VOYAGER_MAIN_HEADER_TEXT =
	"Get Low Cost, High Quality Calls between Shore and Ships";
export const VOYAGER_SUB_HEADER_TEXT =
	"Save up to $7.50 a minute on L-band calls compared to\n" +
	"standard rates and enjoy better call quality";

export const THURAYA_MAIN_HEADER_TEXT = "Welcome to a more connected world";
export const THURAYA_SUB_HEADER_TEXT = "";
export const VIKAND_MAIN_HEADER_TEXT =
	"Welcome to VIKAND Covid-19 Helpline where our staff of maritime seasoned medical professionals will assist you with your questions";
export const VIKAND_DIRECT_MAIN_HEADER_TEXT = "Welcome to VIKAND";
export const INTELL4DESK_MAIN_HEADER_TEXT = "Welcome to Intelli4desks";
export const VIKAND_SUB_HEADER_TEXT =
	"Please register by entering your email address and the license key provided to you from your shipping/management company";
export const INTELL4DESK_SUB_HEADER_TEXT =
	"Please register by entering your email address and the license key provided to you from your company";

export const STATION_SATCOM_MAIN_HEADER_TEXT =
	"Create new VOIP calling account";
export const STATION_SATCOM_SUB_HEADER_TEXT = "";
export const AAGEHEMPEL_MAIN_HEADER_TEXT = "";
export const AAGEHEMPEL_SUB_HEADER_TEXT =
	"Register to the Seamplify Digital Marketplace";
export const ONSHIP_SUB_HEADER_TEXT =
	"Register to the Onship Digital Marketplace";
export const THURAYA_T2M_MAIN_HEADER_TEXT = "Welcome to a more connected world";
export const THURAYA_T2M_SUB_HEADER_TEXT = "Create a new account";
export const BEACONASSIST_MAIN_HEADER_TEXT = "Welcome!";
export const BEACONASSIST_SUB_HEADER_TEXT = "Create New Account";

export const POWERED_BY = "Powered By";

/* =========== Login page ======== */

export const POWERED_BY_LOGO = "/img/frontm-header-logo@2x.png";
export const POWERED_BY_LOGO_T2M = "/img/poweredByFrontM.png";
export const POWERED_BY_B_N_W = "/img/poweredByBlackAndWhite.png";
export const FRONTM_LOGO = "/img/frontm-header-logo@3x.png";
export const FRONTM_LOGO_ALT = "FrontM Logo";
export const VOYAGER_LOGO = "/img/VoyagerVoice-HORZÔÇôlogoREV-master-CMYK.svg";
export const VOYAGER_LOGO_ALT = "Voyager Logo";
// export const THURAYA_LOGO = "img/thuraya_logo.png";
export const THURAYA_LOGO = "img/Thuraya-StarTrack-logo-new.png";
export const THURAYA_URL = "https://www.thuraya.com/thuraya-marinestar";
export const THURAYA_ICON = "img/app-icon.png";
export const THURAYA_LOGO_ALT = "Thuraya Logo";
// export const THURAYA_T2M_LOGO = "img/Thuraya-StarTrack-logo-new.png";
export const THURAYA_T2M_URL = "https://www.thuraya.com";
export const THURAYA_T2M_ICON = "img/t2m_icon-200x150.jpg";
// export const THURAYA_T2M_LOGO_ALT = "Thuraya Logo";
export const VIKAND_LOGO = "img/logos5.jpg";
export const VIKAND_DIRECT_LOGO = "img/VikandLogo.png";
export const INTELL4DESK_LOGO = "img/Intelli4Desks_200x100.png";
export const VIKAND_LOGO_ALT = "Vikand Logo";
export const INTELL4DESK_LOGO_ALT = "Intelli4Desks Logo";
export const STATION_SATCOM_LOGO = "img/StationSatcomNewLogo.png";
export const STATION_SATCOM_LOGO_ALT = "Station Satcom Logo";
export const AAGEHEMPEL_LOGO = "img/seamplify_aagehempel_transparent.svg";
export const AAGEHEMPEL_LOGO_ALT = "Aage Hempel Logo";
export const BEACONASSIST_LOGO = "img/beaconAssistLogo.png";
export const BEACONASSIST_LOGO_ALT = "Beacon Assist Logo";
export const ONSHIP_LOGO = "img/onship-logo-with-tagline2.svg";
export const ONSHIP_LOGO_ALT = "Onship Logo";
export const SIGMA_LOGO = "img/SIGMA_CORPORATE_LOGO.png";
export const SIGMA_LOGO_ALT = "Sigma Logo";
export const THURAYA_T2M_LOGO = "img/sattrack-2022.png";
export const THURAYA_T2M_LOGO_ALT = "Thuraya Logo";
export const ONECARE_LOGO = "img/OC_logoLandingPage.png";
export const ONECARE_LOGO_ALT = "Onecare Logo";

export const VOYAGER_LOGIN_BACKGROUND = "#151C24";
export const THURAYA_LOGIN_BACKGROUND = "#ffff";
export const VIKAND_LOGIN_BACKGROUND = "#ffff";
export const INTELL4DESK_LOGIN_BACKGROUND = "#ffff";
export const STATION_SATCOM_LOGIN_BACKGROUND = "#ffff";
export const AAGEHEMPEL_LOGIN_BACKGROUND = "#ffff";
export const BEACONASSIST_LOGIN_BACKGROUND = "#fff";
export const ONSHIP_LOGIN_BACKGROUND = "#fff";
export const SIGMA_LOGIN_BACKGROUND = "#fff";
export const ONSHIP_SIGNUP_BACKGROUND = "img/onship-loginNew2.png";
export const SIGMA_SIGNUP_BACKGROUND = "img/SIGMA_SIGNUP_IMAGE.jpg";
export const ONSHIP_VERIFY_BACKGROUND = "img/onboarding-onship-img@2x.png";
export const THURAYA_T2M_LOGIN_BACKGROUND = "#ffff";
export const THURAYA_T2M_SIGNUP_BACKGROUND = "img/t2m_background_1800x2000.jpg";
export const THURAYA_T2M_VERIFY_BACKGROUND = "img/t2m_background_2880x1580.jpg";
export const ONECARE_SIGNUP_BACKGROUND = "img/OC_newLandingPage.jpg";

export const VOYAGER_LOGIN_MAIN_HEADER_TEXT_COLOR = "#fff";
export const VOYAGER_LOGIN_SUB_HEADER_TEXT_COLOR = "#fff";
export const THURAYA_LOGIN_MAIN_HEADER_TEXT_COLOR = "#4A4A4A";
export const THURAYA_LOGIN_SUB_HEADER_TEXT_COLOR = "#666666";
export const VIKAND_LOGIN_MAIN_HEADER_TEXT_COLOR = "#4A4A4A";
export const INTELL4DESK_LOGIN_MAIN_HEADER_TEXT_COLOR = "#4A4A4A";
export const VIKAND_LOGIN_SUB_HEADER_TEXT_COLOR = "#666666";
export const INTELL4DESK_LOGIN_SUB_HEADER_TEXT_COLOR = "#666666";
export const STATION_SATCOM_LOGIN_MAIN_HEADER_TEXT_COLOR = "#4A4A4A";
export const STATION_SATCOM_LOGIN_SUB_HEADER_TEXT_COLOR = "#666666";
export const AAGEHEMPEL_LOGIN_MAIN_HEADER_TEXT_COLOR = "#4A4A4A";
export const AAGEHEMPEL_LOGIN_SUB_HEADER_TEXT_COLOR = "#666666";
export const BEACONASSIST_LOGIN_MAIN_HEADER_TEXT_COLOR = "#4A4A4A";
export const BEACONASSIST_LOGIN_SUB_HEADER_TEXT_COLOR = "#666666";
export const ONECARE_MAIN_HEADER_TEXT_COLOR = "#4A4A4A";
export const ONECARE_SUB_HEADER_TEXT_COLOR = "#666666";

/* ========= Signup background ======== */
export const LOGIN_BACKGROUND_HARDCODED_FOR_THURAYA = "/img/t2mNewLanding.jpeg";
export const VOYAGER_SIGNUP_BACKGROUND = "/img/voyagervoice.jpg";
// export const THURAYA_SIGNUP_BACKGROUND = "/img/thuraya-onboarding-img@3x.png";
export const THURAYA_SIGNUP_BACKGROUND = "/img/new_thuraya_bg.jpg";
export const BEACONASSIST_SIGNUP_BACKGROUND = "/img/whiteBG.jpg";
export const VIKAND_SIGNUP_BACKGROUND = "/img/Frontm-rhs-no-text.jpg";
export const VIKAND_DIRECT_SIGNUP_BACKGROUND = "/img/Vikand_Login_Page_3.png";
export const INTELL4DESK_SIGNUP_BACKGROUND = "/img/intelli4desk_bg.jpg";
export const STATION_SATCOM_SIGNUP_BACKGROUND = "/img/newStationSatcomBG.PNG";
export const FRONTM_SIGNUP_BACKGROUND = "/img/green-background@2x.png";
export const AAGEHEMPEL_SIGNUP_BACKGROUND =
	"/img/2700x3000px-CreateAccount-1-100.jpg";

/* ======= Login background ======== */

/* ======= Reset password ======== */
// export const THURAYA_RESET_BACKGROUND = "/img/thuraya-ship-bg.png";
export const THURAYA_RESET_BACKGROUND = "/img/new_thuraya_bg.jpg";
export const VIKAND_RESET_BACKGROUND = "/img/Frontm-rhs-no-text.jpg";
export const BEACONASSIST_RESET_BACKGROUND = "/img/whiteBG.jpg";
export const VIKAND_DIRECT_RESET_BACKGROUND =
	"/img/AccountResetPasswordBackgroundImage.png";
export const INTELL4DESK_RESET_BACKGROUND = "/img/intelli4desk_bg.jpg";
export const AAGEHEMPEL_RESET_BACKGROUND =
	"/img/2700x3000px-Passwordchange-1-100.jpg";
export const AAGEHEMPEL_RESET_BACKGROUND_WIDER =
	"/img/2880x1580px-Passwordchange-2-100.jpg";
export const STATION_SATCOM_RESET_BACKGROUND =
	"/img/stationsatcom-onboarding-img.png";
export const ONSHIP_RESET_BACKGROUND = "/img/onboarding-onship-img@2x.png";
export const SIGMA_RESET_BACKGROUND = "/img/onboarding-onship-img@2x.png";
export const THURAYA_T2M_RESET_BACKGROUND = "/img/t2m_background_2880x1580.jpg";
export const ONECARE_RESET_BACKGROUND = "/img/OC_PasswordReset.jpg";

// CLIENT PROD URLS

export const AAGEHEMPEL_PROD_URL = "app.seamplifymaritime.com";
export const ONSHIP_PROD_URL = "onship.app";
export const THURAYA_T2M_PROD_URL = "sattrackland.thuraya.com";
export const THURAYA_PROD_URL = "sattrack.thuraya.com";
export const BEACONASSIST_PROD_URL = "beaconassist.frontm.com";
export const VIKAND_PROD_URL = "vikand.frontm.com";
export const VIKAND_HEALTH_PROD_URL = "vikandtelehealth.com";
export const STATIONSATCOM_PROD_URL = "ssvideokonnect.com";
export const ONECARE_PROD_URL = "onecare.frontm.com";
export const SIGMA_PROD_URL = "sigmaxpoc.frontm.com";

export const SOCKET = {
	STREAM_QUEUE_MSG: null,
};
